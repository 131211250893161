// Function to show the frame variants for the selected color variant
function showVariantOptions(productId, itemVariantId) {
    // console.log('showVariantOptions Function Called: ', productId, itemVariantId);

    // Hide all variant options for the specific product
    document.querySelectorAll('#product-' + productId + ' .variant-options').forEach(function (el) {
        el.style.display = 'none';
    });

    // Show the variant options for the selected item variant, if they exist
    var variantOptionsEl = document.getElementById('variant-options-' + productId + '-' + itemVariantId);
    if (variantOptionsEl) {
        variantOptionsEl.style.display = 'flex';
    }

    // Remove the selected and active classes from all color variant buttons within the product
    document.querySelectorAll('#product-' + productId + ' .variant-toggle-button-swatch, #product-' + productId + ' .variant-toggle-button').forEach(function (el) {
        el.classList.remove('variant-toggle-button-selected', 'active');
    });

    // Add the selected and active classes to the clicked color variant button
    var selectedButton = document.querySelector('#product-' + productId + ' [onclick="showVariantOptions(\'' + productId + '\', \'' + itemVariantId + '\')"]');
    if (selectedButton) {
        selectedButton.classList.add('variant-toggle-button-selected', 'active');
    }

    // Update the URL with the selected color variant
    updateUrlWithVariantOptions(productId, itemVariantId);

    // Update the main image to the selected variant's image
    var newImage = selectedButton.getAttribute('data-variant-hover-image');
    var targetImageHolder = selectedButton.getAttribute('data-target-hover-image-holder');
    if (newImage && targetImageHolder) {
        document.getElementById(targetImageHolder).src = newImage;
        document.getElementById(targetImageHolder).style.opacity = '1';
    }
};

// Function to update the URL with the selected variant and variant option
function updateUrlWithVariantOptions(productId, itemVariantId, variantOptionSlug) {
    // Get the current URL
    var currentUrl = new URL(window.location.href);

    // Get the selected color variant
    var selectedColorVariant = document.querySelector('#product-' + productId + ' .variant-toggle-button-swatch.variant-toggle-button-selected, #product-' + productId + ' .variant-toggle-button.variant-toggle-button-selected');
    var selectedColorVariantSlug = selectedColorVariant ? selectedColorVariant.getAttribute('title') : null;

    // Update the URL parameters
    if (selectedColorVariantSlug) {
        currentUrl.searchParams.set('variant', selectedColorVariantSlug);
    }
    if (variantOptionSlug) {
        currentUrl.searchParams.set('variantOption', variantOptionSlug);
    }

    // Push the updated URL to the browser history
    // window.history.pushState({}, '', currentUrl);
}

// Function to change the image to the selected variant's option image (Variant 2)
function updateVariantOptionImage(productId, imageURL, collectionItemUrl, activeButtonId) {
    var targetImageLink = document.querySelector('#collection-item-preview-tile-cover-images-' + productId);
    targetImageLink.href = collectionItemUrl;

    var activeButton = document.querySelector('#' + activeButtonId);

    var targetImageHolder = document.querySelector('#collection-item-dynamic-image-holder-' + productId);
    if (targetImageHolder) {
        targetImageHolder.src = imageURL;
        targetImageHolder.style.opacity = '1';
    }
    
    
    // Remove the selected and active classes from all color variant buttons within the product
    document.querySelectorAll('#product-' + productId + ' .variant-toggle-button-swatch-option, #product-' + productId + ' .variant-toggle-button-option').forEach(function (el) {
        el.classList.remove('variant-toggle-button-selected', 'active');
    });

    // Add the selected and active classes to the clicked color variant button
    if (activeButton) {
        activeButton.classList.add('variant-toggle-button-selected', 'active');
    }
}

function initPriceRangeSlider() {
    const parent = document.querySelector(".range-slider");
    if (!parent) return;

    const rangeInputs = parent.querySelectorAll("input[type=range]");
    const numberInputs = parent.querySelectorAll("input[type=number]");

    function updatePriceLabels(min, max) {
        document.getElementById('minPriceLabel').textContent = min;
        document.getElementById('maxPriceLabel').textContent = max;
    }

    function handleRangeInput() {
        let [min, max] = rangeInputs.map(input => parseFloat(input.value));
        if (min > max) [min, max] = [max, min];

        rangeInputs[0].value = min;
        rangeInputs[1].value = max;

        updatePriceLabels(min, max);
    }

    function handleNumberInput() {
        let [min, max] = numberInputs.map(input => parseFloat(input.value));
        if (min > max) [min, max] = [max, min];

        numberInputs[0].value = min;
        numberInputs[1].value = max;
        rangeInputs[0].value = min;
        rangeInputs[1].value = max;
    }

    rangeInputs.forEach(input => input.addEventListener('input', handleRangeInput));
    numberInputs.forEach(input => input.addEventListener('input', handleNumberInput));
}

// Make the function globally accessible
window.initPriceRangeSlider = initPriceRangeSlider;
window.showVariantOptions = showVariantOptions;
window.updateUrlWithVariantOptions = updateUrlWithVariantOptions;
window.updateVariantOptionImage = updateVariantOptionImage;

/* document.addEventListener('DOMContentLoaded', () => {
    console.log('Category.js loaded');
}); */